import React from 'react'
import logo from './Katavla.svg';
import './App.css';

const App = () => {
  return (
    <div>
    <header>
      <div className='katavlaLogo'>
      <img src={logo} alt="Katmerli Tavla" width={450} />
      </div>
    </header>
  </div>
  )
}

export default App